<template>
	<div class="jobDetail">
		<div class="jobDetail-title">
			<span class="jobDetail-title-val">岗位详情</span>
		</div>
		<div class="jobDetail-content">
			<span class="jobDetail-content-title">{{jobDetail.name}}</span>
			<span class="jobDetail-content-val">{{jobDetail.details}}</span>
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">岗位时间</span>
						<span class="common-table-text">{{jobDetail.postTime}}</span>
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">可报名人数</span>
						<span class="common-table-text">{{jobDetail.maxPeo}}人</span>
					</div>
					<div class="common-table-col">
						<span class="common-table-label">在岗人数</span>
						<span class="common-table-text">{{jobDetail.admittedNum}}人</span>
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">岗位类型</span>
						<span class="common-table-text">{{jobDetail.postTypeName}}</span>
					</div>
					<div class="common-table-col">
						<span class="common-table-label">每人工资</span>
						<span class="common-table-text">{{jobDetail.salary}}</span>
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col" v-if="jobDetail.contact">
						<span class="common-table-label">联系人</span>
						<span class="common-table-text">{{jobDetail.contact}}</span>
					</div>
					<div class="common-table-col" v-if="jobDetail.contactPhone">
						<span class="common-table-label">联系方式</span>
						<span class="common-table-text">{{jobDetail.contactPhone}}</span>
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">岗位地址</span>
						<span class="common-table-text">{{jobDetail.address}}</span>
					</div>
				</div>
				<div class="common-table-row" v-if="jobDetail.postDuty">
					<div class="common-table-col">
						<span class="common-table-label">岗位要求</span>
						<span class="common-table-text">{{jobDetail.postDuty}}</span>
					</div>
				</div>
			</div>
			<div class="jobDetail-content-user">
				<span>发布人：{{jobDetail.createAccount}}</span>
				<span>创建时间：{{jobDetail.createTime}}</span>
			</div>
		</div>
		<div class="jobDetail-title">
			<span class="jobDetail-title-val">报名详情</span>
			<div class="jobDetail-title-btn">
				<span @click="handleFn('resume')" class="jobDetail-title-default"
					v-if="(jobDetail.postType=='SO_FIX_TUTOR'||jobDetail.postType=='SO_PART_TIME')">下载全部简历</span>
				<span v-else class="flex-end">
					<span @click="handleFn('xls')" class="jobDetail-title-default"
						:class="{'active':!jobDetail.workingSchedule}"
						v-if="(jobDetail.postType=='S_FIX'||jobDetail.postType=='C_FIX')&&jobDetail.signUp.length>0">
						{{jobDetail.workingSchedule?'排班表已上传':'排班表未上传'}}
					</span>
					<span @click="handleFn('add')" class="jobDetail-title-primary"
						v-if="jobDetail.postType=='S_FIX'||jobDetail.postType=='S_TEMP'">添加学生</span>
				</span>
				<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
					:before-upload="beforeUpload" :http-request="handleCustomRequest"></el-upload>
			</div>
		</div>
		<div class="jobDetail-apply" v-if="jobDetail.signUp&&jobDetail.signUp.length>0">
			<div v-for="(item, index) in jobDetail.signUp" :key="index" class="jobDetail-apply-item">
				<div class="apply-item-inner">
					<div class="apply-item-user">
						<img class="item-user-avatar" :src="item.avatar" alt="" v-if="item.avatar">
						<img class="item-user-avatar" src="@/assets/images/pc/common/avatar.png" alt="" v-else>
						<div class="item-user-content">
							<div class="user-content-top">
								<div class="flex-item">
									<span class="user-content-name">{{item.name}}</span>
									<span class="user-content-num">{{item.studentNumber}}</span>
								</div>
								<div class="flex-item" style="justify-content: flex-end;">
									<div @click="handleFn('singleResume',item)" class="flex-item"
										v-if="jobDetail.postType!=='S_FIX'&&jobDetail.postType!=='S_TEMP'&&jobDetail.postType!=='C_FIX'">
										<!-- <img v-if="item.resume" class="user-content-resumeicon"
											src="@/assets/images/pc/activity/resumeed.png" alt=""> -->
										<img v-if="item.resume" class="user-content-resumeicon"
											src="@/assets/images/pc/activity/resume.png" alt="">
										<!-- <span v-if="item.resume" style="color: #15D2B7;"
											class="user-content-resumetext">简历</span> -->
										<span v-if="item.resume" style="color: #606266;margin-right:0" class="user-content-resumetext">简历</span>
									</div>
									<span v-if="item.workingHour&&jobDetail.postType!='SO_FIX_TUTOR'&&jobDetail.postType!='SO_PART_TIME'" style="color: #606266;"
										class="user-content-resumetext">
										工时：{{item.workingHour}} h
									</span>
									<div @click="handleFn('class',item)" class="flex-item" style="margin-left:10px" v-if="item.curriculum">
										<img  class="user-content-resumeicon"
											src="@/assets/images/pc/activity/class-icon.png" alt="">
										<span  style="color: #606266;margin-right:0;" class="user-content-resumetext" >课表</span>
									</div>
								</div>
							</div>
							<div class="user-content-bottom">
								<span class="user-content-class">{{item.college}}{{item.faculty}}</span>

								<span class="phone-number" v-if="item.phone">联系方式：{{item.phone}}</span>
								<!-- <span v-if="item.curriculum" @click="handleFn('class',item)"
									class="user-content-btn">查看课表 ></span> -->
							</div>
						</div>
						<img class="item-user-status" src="@/assets/images/pc/activity/cancel.png" alt=""
							v-if="item.state=='CANCEL'">
						<div v-if="jobDetail.postType=='SO_FIX_TUTOR'||jobDetail.postType=='SO_PART_TIME'">
							<img class="item-user-status" src="@/assets/images/pc/activity/employed.png" alt=""
								v-if="item.state=='ADMISSION'">
							<img class="item-user-status" src="@/assets/images/pc/activity/rejuct.png" alt=""
								v-if="item.state=='NO_ADMISSION'">
						</div>
					</div>
					<div class="apply-item-time" v-if="jobDetail.postType=='S_FIX'||jobDetail.postType=='C_FIX'">
						<span v-for="(ite,inde) in item.workingHours" :key="inde" class="month-box">
							<span v-if="ite.workTime>0">{{ite.workingHourTime}}月：{{ite.workTime}}h</span>
						</span>
						<!-- <span>签退时间：{{item.signEndTime?item.signEndTime:'-'}}</span> -->
					</div>
				</div>
				<div class="apply-item-btn"
					v-if="(jobDetail.postType=='S_FIX'||jobDetail.postType=='S_TEMP')">
					<span v-if="item.state=='REGISTERING'&&isShowCancel" @click="handleFn('apply',item)" class="item-btn-default">取消报名</span>
					<span class="item-btn-default margin-left-auto" @click="handleFn('mess',item)">发送消息</span>
				</div>
				<div v-if="jobDetail.postType!=='S_FIX'&&jobDetail.postType!=='S_TEMP'">
					<div class="apply-item-btn" >
						<div>
							<div class="flex-start" v-if="item.state=='INTERVIEW'||item.state=='REGISTERING'">
								<span @click="handleFn('employ',item)" class="item-btn-primary">录用</span>
								<span @click="handleFn('refuse',item)" class="item-btn-default">拒绝</span>
							</div>
						</div>
						<div class="flex-end">
							<span @click="handleFn('interview',item)" class="item-btn-default margin-left-auto"
							v-if="(item.state=='INTERVIEW'||item.state=='REGISTERING')&&(jobDetail.postType=='SO_FIX_TUTOR'||jobDetail.postType=='SO_PART_TIME')">发送面试消息</span>
							<span class="item-btn-default margin-left-auto" @click="handleFn('mess',item)">发送消息</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-data">暂无报名信息</div>
		<div class="">
			<!-- 添加学生 -->
			<div class="dialog-student">
				<el-dialog @close="closeStudentDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
					:visible.sync="studentVisible">
					<div slot="title" class="dialog-header">
						<span>{{studentTitle}}</span>
						<img @click="closeStudentDialog" src="@/assets/images/pc/common/close.png">
					</div>
					<div class="">
						<el-form :model="addStudent" :rules="addStudentRules" ref="addStudent" class="">
							<el-form-item class="dialog-formitem" label="学生学号:" prop="studentNumber">
								<el-input type="text" class="dialog-formitem-ipt" v-model="addStudent.studentNumber"
									placeholder="请输入">
								</el-input>
								<!-- <el-select class="dialog-formitem-select" v-model="addStudent.num" placeholder="请选择">
									<el-option v-for="item in planList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select> -->
							</el-form-item>
							<el-form-item class="dialog-formitem" label="学生姓名:" prop="name">
								<el-input type="text" class="dialog-formitem-ipt" v-model="addStudent.name"
									placeholder="请输入">
								</el-input>
							</el-form-item>
							<span class="dialog-formitem-text">注：添加的学生学号和姓名必须匹配，否则添加不成功，其余 学生相关信息自动获取</span>
						</el-form>
					</div>
					<span slot="footer" class="dialog-footer flex-item">
						<span @click="closeStudentDialog" class="cancel-btn">取 消</span>
						<span @click="submitDialog" class="submit-btn">确 定</span>
					</span>
				</el-dialog>
			</div>
			<!-- 查看课表 -->
			<div class="dialog-class">
				<el-dialog @close="closeClassDialog" :modal="false" class="dialog-bg" :show-close="false"
					:visible.sync="classVisible">
					<div class="dialog-class-inner">
						<img class="class-inner-img" :src="curriculum" alt="">
						<img @click="closeClassDialog" class="class-inner-close"
							src="@/assets/images/pc/activity/close.png" alt="">
					</div>
				</el-dialog>
			</div>
			<div class="dialog-schedule">
				<!-- 排班表管理 -->
				<el-dialog @close="closeScheduleDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
					:visible.sync="scheduleVisible">
					<div slot="title" class="dialog-header">
						<span>{{scheduleTitle}}</span>
						<img @click="closeScheduleDialog" src="@/assets/images/pc/common/close.png">
					</div>
					<div class="dialog-schedule-file">
						<img :src="formatFileType(jobDetail.workingScheduleUrl) == '.pdf'?pdfUrl:jpgUrl"
							class="dialog-schedule-icon" alt="">
						<span class="dialog-schedule-name">排班表{{formatFileType(jobDetail.workingScheduleUrl)}}</span>
						<span @click="previewFile" class="dialog-schedule-btn">查看</span>
						<!-- <img class="dialog-schedule-img" :src="jobDetail.workingScheduleUrl" alt=""> -->
					</div>
					<span slot="footer" class="dialog-schedule-footer">
						<span @click="handleUpload">更换</span>
					</span>
				</el-dialog>
			</div>
			<!-- 面试消息 -->
			<div class="dialog-refuse">
				<el-dialog @close="closeInterviewDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
					:visible.sync="interviewVisible">
					<div slot="title" class="dialog-header">
						<span>{{refuseTitle}}</span>
						<img @click="closeInterviewDialog" src="@/assets/images/pc/common/close.png">
					</div>
					<div class="">
						<el-form :model="sendInterview" :rules="sendInterviewRules" ref="sendInterview" class="">
							<el-form-item class="dialog-formitem" label="消息标题:" prop="title">
								<el-input type="text" class="dialog-formitem-ipt" v-model="sendInterview.title"
									:placeholder="dialogType=='mess'?'请输入标题':'请输入 岗位名称+面试通知'">
								</el-input>
							</el-form-item>
							<el-form-item class="dialog-formitem" label="消息内容:" prop="content">
								<el-input type="textarea" class="dialog-formitem-textarea"
									v-model="sendInterview.content" :placeholder="dialogType=='mess'?'请输入消息内容':'请输入 面试形式、面试时间、面试地点等相关内容'">
								</el-input>
							</el-form-item>
						</el-form>
					</div>
					<span slot="footer" class="dialog-footer flex-item">
						<span @click="closeInterviewDialog" class="cancel-btn">取 消</span>
						<span @click="submitInterviewDialog" class="submit-btn">确 定</span>
					</span>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
	import storage from 'store'
	import {
		getJobDetail,
		addStudent,
		messInterview,
		resultInterview,
		resumeDown,
		studentSignUpPcCancel,
		workingScheduleUploadPc
	} from '@/api/pc/index'
	import {
		upLoadFileMb
	} from "@/api/mobile/oss"
	export default {
		data() {
			return {
				jobDetail: {},
				studentVisible: false,
				studentTitle: "添加学生",
				addStudent: {},
				addStudentRules: {
					studentNumber: [{
						required: true,
						message: "请输入学生学号",
						trigger: "change"
					}],
					name: [{
						required: true,
						message: "请输入学生姓名",
						trigger: "change"
					}]
				},
				classVisible: false,
				scheduleTitle: "排班表管理",
				scheduleVisible: false,
				interviewVisible: false,
				refuseTitle: "面试消息发送",
				sendInterviewRules: {
					title: [{
						required: true,
						message: "请输入通知标题",
						trigger: "change"
					}],
					content: [{
						required: true,
						message: "请输入通知内容",
						trigger: "change"
					}]
				},
				sendInterview: {},
				detailId: "",
				limitSize: 5,
				curriculum: "",
				upLoadUrl: "",
				isShowCancel: true,
				jpgUrl: require("@/assets/images/pc/common/jpg.png"),
				pdfUrl: require("@/assets/images/pc/common/pdf.png"),
				userInfo:{},
				dialogType:""
			}
		},
		watch: {
			studentVisible: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.$refs["addStudent"].clearValidate()
						})
					}
				},
				deep: true
			},
			interviewVisible:{
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.$refs["sendInterview"].clearValidate()
						})
					}
				},
				deep: true
			}
		},
		created() {
			if (this.$route.query.id) {
				this.detailId = this.$route.query.id
			}

		},
		mounted() {
			this.userInfo = storage.get("accessInfo")
			this.getJobData()
		},
		methods: {
			nameFormat(name) {
				if (name) {
					if (name.indexOf('用户-') != -1) {
						// console.log("nameIndex", name.indexOf('用户-'), name)
						return name.substring(3, name.length)
					} else {
						return name
					}
				} else {
					return ""
				}
			},
			isShowCancelBtn(endTime) {
				if (endTime) {
					let nowDate = new Date().getTime()
					let newEndtime = new Date(endTime).getTime()
					if (nowDate > newEndtime) {
						this.isShowCancel = false
					} else {
						this.isShowCancel = true
					}
				}
			},
			//获取岗位详情信息
			getJobData() {
				getJobDetail(this.detailId).then(res => {
					if (res.code == 0) {
						if(res.data.signUp){
							res.data.signUp.forEach(el=>{
								if(el.resume){
									res.data.isResume = true
								}
							})
						}
						this.jobDetail = res.data
						this.isShowCancelBtn(res.data.endTime)
					}
				})
			},
			handleFn(type, item) {
				if((this.userInfo.id===this.jobDetail.createBy)||this.userInfo.superAdmin==true){
					switch (type) {
						case "apply":
							this.$confirm('确定取消当前岗位学生的报名吗？', '信息提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								studentSignUpPcCancel({
									id: item.id
								}).then(res => {
									if (res.code == 0) {
										this.$message({
											type: 'success',
											message: '操作成功!'
										});
									}
									this.getJobData()
								})
							}).catch(() => {});
							break;
						case "employ":
							this.$confirm('确定要录用当前学生吗？', '信息提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.handleResult(type, item)
							}).catch(() => {});
							break;
						case "mess":
							this.refuseTitle = "消息发送"
							this.interviewVisible = true
							this.sendInterview.studentId = item.studentId
							this.sendInterview.postId = this.detailId
							this.sendInterview.commonMsg = true
							this.dialogType = type
							break;
						case "refuse":
							this.$confirm('确定要拒绝当前学生的报名吗？', '信息提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.handleResult(type, item)
							}).catch(() => {});
							break;
						case "xls":
							if (!this.jobDetail.workingScheduleUrl) {
								//排班表上传
								this.handleUpload()
							} else {
								this.scheduleVisible = true
							}
							// 
							break;
						case "resume":
							this.handleResume()
							break;
						case "singleResume":
							if(item.resume){
								window.open(item.resume)
							}
							break;
						case "add":
							this.studentVisible = true
							break;
						case "class":
							this.curriculum = item.curriculum
							this.classVisible = true
							break;
						case "interview":
							this.dialogType = type
							this.interviewVisible = true
							this.sendInterview.studentId = item.studentId
							this.sendInterview.postId = this.detailId
							break;
					}
				}else{
					this.$message({
						type: 'warning',
						message: '暂无权限'
					});
				}
			},
			//排班表上传
			handleUpload() {
				// upLoadWorkingSchedule() {
				document.querySelector(".uploadImage input").click();
				// },
			},
			previewFile() {
				window.open(this.jobDetail.workingScheduleUrl)
			},
			// 处理文件类型
			formatFileType(path) {
				if (path) {
					let fileType = path.substring(path.lastIndexOf("."));
					return fileType
				}
			},
			// 上传按钮   限制图片大小
			beforeUpload(file) {
				console.log("beforeUpload", file);
				const isLt5M = file.size / 1024 / 1024 < this.limitSize;
				if (!isLt5M) {
					this.$toast("上传文件大小不能超过 5MB!")
					return false;
				}
				let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
				let fileName = file.name;
				// let fileType = file.type;
				if (fileType != "png" && fileType != "jpg" && fileType != "jpeg" && fileType != "pdf") {
					this.$toast("上传文件只能是jpg、png、jpeg、pdf格式!")
					return false;
				}
			},
			handleCustomRequest(data) {
				console.log("handleCustomRequest", data)
				this.fileData = []
				const formData = new FormData();
				formData.append('file', data.file);
				upLoadFileMb(formData).then((res) => {
					console.log("上传成功", res)
					// this.userInfo.avatar = res.data.accessUrl
					let params = {
						id: this.detailId,
						workingScheduleUrl: res.data.accessUrl
					}
					workingScheduleUploadPc(params).then((res) => {
						if (res.code == 0) {
							this.$message({
								type: 'success',
								message: '排班表上传成功!'
							});
							this.getJobData()
						}
					})
				})
			},
			//面试录用拒绝
			handleResult(type, item) {
				var params = {
					id: item.id,
					signUpStatus: "ADMISSION"
				}
				if (type == 'refuse') {
					this.$set(params, "signUpStatus", "NO_ADMISSION")
				}
				resultInterview(params, ).then(res => {
					if (res.code == 0) {
						this.$message({
							type: 'success',
							message: '操作成功!'
						});
						this.getJobData()
					}
				})
			},
			//下载简历
			handleResume() {
				if(!this.jobDetail.isResume){
					this.$message({
						type: 'warning',
						message: '暂无简历下载'
					});
					return
				}
				resumeDown(this.detailId).then((res) => {
					const blob = new Blob([res], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					})
					// console.log("downLoadActivity", URL.createObjectURL(blob))
					// window.location.href = URL.createObjectURL(blob)
					// const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
					if ('download' in document.createElement('a')) {
						// 非IE下载
						const elink = document.createElement('a')
						elink.download = "全部简历.zip"
						elink.style.display = 'none'
						elink.href = URL.createObjectURL(blob)
						document.body.appendChild(elink)
						elink.click()
						URL.revokeObjectURL(elink.href) // 释放URL 对象
						document.body.removeChild(elink)
					} else {
						// IE10+下载
						navigator.msSaveBlob(blob, "岗位列表.xlsx")
					}
				})
				// resumeDown(this.detailId).then(res=>{
				// 	console.log(res)
				// })
			},
			closeStudentDialog() {
				this.studentVisible = false
			},
			submitDialog() {
				if (this.jobDetail.signUp.length == this.jobDetail.maxPeo) {
					this.$message.error({
						message: '该岗位人数超出限制无法添加，如需继续添加请修改人数上限',
						duration: 3000
					});
					return
				}
				this.$refs["addStudent"].validate(valid => {
					if (valid) {
						this.addStudent.postId = this.detailId
						addStudent(this.addStudent).then(res => {
							if (res.code == 0) {
								this.studentVisible = false
								this.getJobData()
							}
						})
					}
				})
			},
			//面试消息发送
			submitInterviewDialog() {
				this.$refs["sendInterview"].validate(valid => {
					if (valid) {
						messInterview(this.sendInterview).then(res => {
							if (res.code == 0) {
								this.interviewVisible = false
								this.$message({
									type: 'success',
									message: '发送成功!'
								});
								this.getJobData()
							}
						})
					}
				})
			},
			closeClassDialog() {
				this.classVisible = false
			},
			closeScheduleDialog() {
				this.scheduleVisible = false
			},
			closeInterviewDialog() {
				this.interviewVisible = false
				this.sendInterview = {}
				this.$refs["sendInterview"].clearValidate()
			},
		},
	}
</script>

<style lang="scss" scoped>
	.no-data {
		font-size: 14px;
		color: #909399;
		text-align: center;
		margin-top: 100px;
		
	}

	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}

	/deep/.el-textarea__inner {
		height: 80px;
		border: 0;
		resize: none;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}

	/deep/.el-dialog__header {
		padding: 0 24px !important;
		background: #F7F8F9;
		border-radius: 4px 4px 0px 0px;
	}

	/deep/.el-form-item__label {
		line-height: 36px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #909399;
	}

	/deep/.dialog-student .el-dialog__body {
		padding: 30px 20px 0 20px;
	}

	/deep/.dialog-class .el-dialog__body {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/deep/.dialog-schedule .el-dialog__body {
		padding: 20px 20px 0 20px;
	}

	.margin-left-auto {
		margin-left: auto;
	}

	.flex-item {
		display: flex;
		align-items: center;
	}

	.submit-btn {
		width: 72px;
		height: 33px;
		background: #4392FA;
		border-radius: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.cancel-btn {
		width: 72px;
		height: 33px;
		border: 1px solid #4392FA;
		border-radius: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4392FA;
		margin-left: auto;
		margin-right: 16px;
		box-sizing: border-box;
	}

	.jobDetail {
		.jobDetail-title {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.jobDetail-title-val {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 22px;
				color: #303133;
				margin-bottom: 16px;
				padding-left: 10px;
				border-left: 4px solid #4392FA;
				margin: 16px 0;
			}

			.jobDetail-title-btn {
				display: flex;
				align-items: center;

				.flex-end {
					display: flex;
					justify-content: flex-end;
				}

				.active {
					color: #FF794D !important;
					border: 1px solid #FF794D !important;
				}

				.jobDetail-title-default {
					min-width: 72px;
					height: 28px;
					border: 1px solid #4392FA;
					border-radius: 2px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #4392FA;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 8px;
					padding: 0 8px;
					cursor: pointer;
				}

				.jobDetail-title-primary {
					width: 72px;
					height: 28px;
					border-radius: 2px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #4392FA;
					cursor: pointer;
				}
			}
		}

		.jobDetail-content {
			display: flex;
			flex-direction: column;

			.jobDetail-content-title {
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 25px;
				color: #303133;
				margin-bottom: 10px;
			}

			.jobDetail-content-val {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 21px;
				color: #606266;
			}

			.common-table {
				width: 100%;
				border-radius: 2px;
				border-top: 1px solid #DCDFE6;
				border-left: 1px solid #DCDFE6;
				display: flex;
				flex-direction: column;
				margin: 10px 0;

				.common-table-row {
					display: flex;
					align-items: center;

					.common-table-col {
						flex: 1;
						display: flex;
						align-items: stretch;

						.common-table-label {
							min-width: 100px;
							line-height: 36px;
							background: #F6F7F9;
							border-right: 1px solid #DCDFE6;
							border-bottom: 1px solid #DCDFE6;
							box-sizing: border-box;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #606266;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						.common-table-val {
							flex: 1;
							box-sizing: border-box;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #303133;
							border-right: 1px solid #DCDFE6;
							border-bottom: 1px solid #DCDFE6;
						}

						.common-table-text {
							flex: 1;
							line-height: 36px;
							box-sizing: border-box;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #303133;
							padding: 0 15px;
							border-right: 1px solid #DCDFE6;
							border-bottom: 1px solid #DCDFE6;
							display: flex;
							align-items: center;
							// overflow: hidden;
							// display: -webkit-box;
							// /*设置为弹性盒子*/
							// -webkit-line-clamp: 1;
							// /*最多显示3行*/
							// overflow: hidden;
							// /*超出隐藏*/
							// text-overflow: ellipsis;
							// /*超出显示为省略号*/
							// -webkit-box-orient: vertical;
							// word-break: break-all;
						}

						.common-table-verify {
							flex: 1;
							display: flex;
							flex-direction: column;
							padding: 20px 10px;
							border-bottom: 1px solid #DCDFE6;

							.table-verify-item {
								display: flex;
								align-items: center;
								margin-bottom: 16px;

								.verify-item-label {
									min-width: 60px;
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: bold;
									line-height: 20px;
									color: #303133;
									margin-right: 11px;
								}

								.verify-item-ipt {
									flex: 1;
								}
							}
						}
					}
				}
			}

			.jobDetail-content-user {
				display: flex;
				align-items: center;
				padding-bottom: 20px;
				box-sizing: border-box;
				border-bottom: 1px solid #DCDFE6;

				span {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #606266;
					margin-right: 40px;
				}
			}
		}

		.jobDetail-apply {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.jobDetail-apply-item {
				display: flex;
				flex-direction: column;
				margin-bottom: 16px;

				.apply-item-inner {
					width: 460px;
					border: 1px solid #DCDFE6;
					display: flex;
					flex-direction: column;

					.apply-item-user {
						width: 460px;
						display: flex;
						align-items: center;
						padding: 16px 16px;
						box-sizing: border-box;
						position: relative;

						.item-user-avatar {
							width: 45px;
							height: 45px;
							border-radius: 50%;
							margin-right: 11px;
						}

						.item-user-content {
							flex: 1;
							display: flex;
							flex-direction: column;

							.user-content-top {
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin-bottom: 5px;

								.user-content-name {
									font-size: 16px;
									font-family: PingFang SC;
									font-weight: bold;
									line-height: 22px;
									color: #303133;
									margin-right: 10px;
								}

								.user-content-num {
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: 400;
									line-height: 22px;
									color: #606266;
								}

								.user-content-resumeicon {
									width: 16px;
									height: 16px;
									margin-right: 2px;
									display: flex;
									align-items: center;
									cursor: pointer;
								}

								.user-content-resumetext {
									cursor: pointer;
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: 400;
									line-height: 20px;
									margin-right: 10px;
								}
							}

							.user-content-bottom {
								display: flex;
								align-items: center;
								justify-content: space-between;
								.phone-number{
									font-size:12px;
									color:#909399;
								}
								.user-content-class {
									font-size: 12px;
									font-family: PingFang SC;
									font-weight: 400;
									line-height: 17px;
									color: #909399;
								}

								.user-content-btn {
									font-size: 12px;
									font-family: PingFang SC;
									font-weight: 400;
									line-height: 17px;
									color: #606266;
									cursor: pointer;
								}
							}
						}

						.item-user-status {
							position: absolute;
							right: 0;
							top: 0;
							width: 40px;
							height: 40px;
						}
					}

					.apply-item-time {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						flex-wrap: wrap;
						background: #F7F8F9;
						min-height: 25px;
						padding-left:26px;
						box-sizing: border-box;
						.month-box {
							margin-right:20px;
							font-size: 12px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 25px;
							color: #909399;
							width:85px;
						}
						.month-box:nth-child(4n){
							margin-right:0!important;
						}
					}
				}

				.flex-start {
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}
				.flex-end {
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}
				.apply-item-btn {
					margin-top: 8px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.item-btn-primary {
						min-width: 64px;
						height: 21px;
						background: #4392FA;
						border: 1px solid #0091FF;
						border-radius: 2px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #FFFFFF;
						cursor: pointer;
						margin-right: 8px;
						padding: 0 8px;
						box-sizing: border-box;
					}

					.item-btn-default {
						min-width: 64px;
						height: 21px;
						border: 1px solid #4392FA;
						border-radius: 2px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #4392FA;
						cursor: pointer;
						margin-right: 8px;
						padding: 0 8px;
						box-sizing: border-box;
					}
				}
			}
		}
	}

	.dialog-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 50px;

		span {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: bold;
			line-height: 50px;
			color: #303133;
			display: flex;
			align-items: center;
		}

		img {
			width: 15px;
			height: 15px;
			cursor: pointer;
		}
	}

	.dialog-formitem {
		width: 100%;
		display: flex;
		align-items: center;

		.dialog-formitem-ipt {
			flex: 1;
			border: 1px solid #DCDFE6;
			margin-right: 18px;
			min-width: 350px;
		}

		.dialog-formitem-textarea {
			flex: 1;
			border: 1px solid #DCDFE6;
			margin-right: 18px;
			min-width: 350px;
		}

		.dialog-formitem-select {
			flex: 1;
			border: 1px solid #DCDFE6;
			margin-right: 18px;
		}
	}

	.dialog-formitem-text {
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #909399;
		word-break: break-all;
		line-height: 18px;
	}

	.dialog-class {
		.dialog-class-inner {
			padding: 10px 0px;
			position: relative;

			.class-inner-img {
				width: 100%;
				height: auto;
				max-height: 280px;
			}

			.class-inner-close {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				position: absolute;
				bottom: -110px;
				left: 50%;
				transform: translate(-50%, -50%);
				cursor: pointer;
			}
		}
	}

	.dialog-schedule {
		.dialog-schedule-file {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #F7F8F9;
			border-radius: 4px 4px 4px 4px;
			border: 2px solid #EAECF0;
			padding: 10px 32px;
			box-sizing: border-box;

			.dialog-schedule-icon {
				width: 35px;
				height: 40px;
			}

			.dialog-schedule-name {
				flex: 1;
				font-size: 16px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #303133;
				margin: 0 20px;
			}

			.dialog-schedule-btn {
				font-size: 16px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #4392FA;
				cursor: pointer;
			}

			.dialog-schedule-img {
				width: 100%;
				height: auto;
				max-height: 260px;
			}
		}

		.dialog-schedule-footer {
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				width: 96px;
				height: 36px;
				background: #4392FA;
				border-radius: 2px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				cursor: pointer;
			}
		}
	}
</style>
