import axios from '@/utils/request'
let Api = {
	upLoadFile: "/uploadFile/upload",
}
// 文件上传
export function upLoadFileMb(params) {
	return axios({
		method: 'post',
		url: Api.upLoadFile,
		data: params
	})
}
